import { onMounted, ref } from "vue"

import { useAxios } from "./useAxios"

import { METHODS } from "@/lib/constants"
import { useCacheStore, useAuthStore } from "@/stores"

export const useSelectors = endpointList => {
  const isLoading = ref(false)
  const selectorsData = ref({})
  const auth = useAuthStore()

  if (! auth.isAuthenticated) {
    return { isLoading, selectorsData }
  }

  const cacheStore = useCacheStore()
  const { request } = useAxios()

  onMounted(async () => {
    isLoading.value = true
    try {
      // Map over each endpoint and make a GET request
      const requests = endpointList.map(async ({ url, key }) => {
        cacheStore.cleanExpiredResponses()

        // Check cache first
        const cachedResponse = cacheStore.getResponseByUrl(url)
        if (cachedResponse) {
          selectorsData.value[key] = cachedResponse
          return
        }

        try {
          const response = await request({ method: METHODS.GET, url: url })
          // Store the data in the selectorsData object using the provided key
          selectorsData.value[key] = response.data?.response?.[key]

          // Save response to cache
          cacheStore.setResponse(url, response.data?.response?.[key])
        } catch (error) {
          console.error(`Error fetching ${key} data: ${error}`)
        }
      })

      // Wait for all requests to complete
      await Promise.all(requests)
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      isLoading.value = false
    }
  })

  // Return the loading state and the retrieved data
  return {
    isLoading,
    selectorsData
  }
}
